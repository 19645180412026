import { getGradeAll } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getGradeAll().then(res => {
        this.tableData = res.data;
      });
    }
  }
};